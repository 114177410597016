<template>
  <v-layout
    :style="{
      position: isPc ? 'relative' : undefined,
      flexDirection: isPc ? 'column' : 'row',
    }"
  >
    <JwGnbHeader v-if="isPc" />
    <v-navigation-drawer
      v-model="sideNavWrap.show"
      name="left"
      touchless
      :class="{ 'scroll-bar': isPc }"
      :absolute="isPc"
      :style="{
        width: !isMobile ? '375px' : '100%',
        // height: isPc ? 'auto' : 'calc(100vh - 75px)',
        // bottom: isPc ? '155px' : 'var(--v-layout-bottom)',
        height: isPc ? 'auto' : '100%',
        bottom: isPc ? '155px' : '0',
        paddingBottom: isPc ? '0' : '55px',
        left: isPc ? '50%' : undefined,
        marginLeft: isPc ? '-450px' : undefined,
        paddingTop: isPc ? '50px' : undefined,
      }"
    >
      <v-toolbar color="background" absolute class="jw-app-header jw-app-header--more px-5">
        <v-toolbar-title data-ko="더보기"> {{ $t("more.title") }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="24" :class="{ new: alarmNotiCount.alarm }" @click="router.push('/more/alarm')">
          <v-icon size="18">jwIcons:svg/bell</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4" :class="{ new: alarmNotiCount.noti }" @click="router.push('/more/notice')">
          <v-icon size="20">jwIcons:svg/bullhorn</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4 me-0" @click="router.push('/more/setting')">
          <v-icon size="20">jwIcons:svg/gear</v-icon>
        </v-btn>
      </v-toolbar>
      <div style="padding-top: 55px !important">
        <swiper v-bind="defaultSwiperOption" class="jw-swiper pt-4 pb-7">
          <template v-if="myGroupDashboard.length">
            <template v-for="(item, index) in myGroupDashboard" :key="`mydashboard-${index}`">
              <swiper-slide class="full" v-if="item.show">
                <div class="jw-more-dashboard jw-more-dashboard--wrap" style="height: 100%">
                  <div class="jw-more-dashboard__header">
                    <div class="title">
                      {{ item.info.grpName }}<small>({{ item.info.ldrName }})</small>
                    </div>
                    <v-spacer></v-spacer>
                    <VBtn
                      variant="flat"
                      minWidth="43"
                      height="25"
                      rounded
                      color="#2DA562"
                      class="fs-12 px-2"
                      v-if="item.info.grpMngrYn"
                      @click.stop="onClickManage(item.info.grpCd)"
                      data-ko="관리"
                      >관리</VBtn
                    >
                  </div>
                  <div class="jw-more-dashboard__body">
                    <div class="body__thumbs only-item">
                      <div class="thumbs__item" @click="onClickSermon(item.info.grpCd, item.sermon.video.url)">
                        <!-- <VImg src="~/assets/images/sample_moretop_01.png" width="100%" height="120" cover> -->
                        <VImg :src="item.sermon.video.thumb" width="100%" height="120" cover>
                          <template v-slot:placeholder>
                            <div class="thumbs-placeholder">
                              <p data-ko="준비중입니다.">{{ $t("more.comment.preparation") }}</p>
                            </div>
                          </template>
                        </VImg>
                        <div class="btn-play" v-if="item.sermon.video.url">
                          <VImg src="~/assets/images/play_circle.png" width="50" height="50" />
                        </div>
                        <span v-if="item.sermon.up" class="up">UP</span>
                        <!-- <div class="item-cover">
                          <div class="item-cover__title" data-ko="설교">
                            <span v-if="item.sermon.up" data-ko="금주">{{ $t("more.thisweek") }}</span> {{ $t("more.sermon") }}
                          </div> -->
                          <!-- <div class="progress">
                            <span class="bar" style="width: 60%"></span>
                          </div> -->
                        <!-- </div> -->
                      </div>
                    </div>

                    <div class="body__desc">
                      <h3 v-if="item.sermon.sermonCd" class="mb-3" data-ko="설교본문">
                        {{ $t("more.sermondesc") }}<br />
                        <p class="mt-2">
                          <small>{{ item.sermon.bible }}</small>
                        </p>
                      </h3>

                      <VBtn
                        variant="flat"
                        height="30"
                        min-width="auto"
                        rounded
                        color="#0056BC"
                        class="px-3"
                        :disalbed="!item.accounts.accountCnt"
                        @click="onClickAccount(item.info.grpCd)"
                        data-ko="헌금"
                      >
                        헌금
                        <!-- {{ $t("more.onlineoffering") }} -->
                      </VBtn>

                      <VBtn
                        variant="flat"
                        height="30"
                        min-width="auto"
                        rounded
                        color="#0056BC"
                        class="px-3 ml-1"
                        :disabled="isEmpty(item.jubo.uploadFile)"
                        @click="onClickWeekly(item.info.grpCd, item.jubo.uploadFile)"
                        data-ko="주보"
                      >
                        {{ $t("more.weekpaper") }}
                      </VBtn>
                      <span v-if="item.jubo.up" class="up">UP</span>
                    </div>
                  </div>

                  <div class="jw-more-dashboard__footer" v-if="item.links.length">
                    <template v-for="(itm, idx) in item.links">
                      <VBtn
                        variant="text"
                        rounded="0"
                        height="40"
                        class="px-2"
                        v-if="!isEmpty(itm.linkName)"
                        :key="idx"
                        :href="itm.link"
                        target="_blank"
                        >{{ itm.linkName }}</VBtn
                      >
                    </template>
                  </div>
                </div>
              </swiper-slide>
            </template>
          </template>
          <template v-else>
            <swiper-slide class="full">
              <div class="jw-more-dashboard jw-more-dashboard--wrap">
                <div class="jw-more-dashboard__header">
                  <div class="title" data-ko="우리 교회 소식 / 주보 / 설교 등록">{{ $t("more.regist.title") }}</div>
                  <v-spacer />
                  <!-- <v-btn variant="text" size="small" color="white" @click.stop="aboutMyGroup = true">
                    <v-icon size="16" class="mr-02">jwIcons:svg/circled-question</v-icon>
                    알아보기
                  </v-btn> -->
                  <!-- <v-btn variant="text" size="small" color="white" @click.stop="router.push('/more/findMyGroup')"> 등록하기 </v-btn> -->
                  <v-btn variant="text" size="small" color="white" @click.stop="router.push('/more/findMyGroup')" data-ko="알아보기">
                    <v-icon size="16" class="mr-02">jwIcons:svg/circled-question</v-icon>
                    {{ $t("more.findout") }}
                  </v-btn>
                </div>
              </div>
            </swiper-slide>
          </template>

          <swiper-slide class="full" v-if="myGroupDashboard.length">
            <div class="jw-more-dashboard jw-more-dashboard--wrap">
              <div class="jw-more-dashboard__header order justify-center py-0">
                <v-spacer></v-spacer>
                <!-- <div class="dashboard-order--util">
                  순서 설정 하기
                  <v-switch
                    v-model="dashboardSetting.orderEdit"
                    density="compact"
                    hide-details
                    single-line
                    color="#2DA562"
                    class="ml-3"
                  ></v-switch>
                </div>
                <v-spacer></v-spacer> -->
                <VBtn
                  variant="text"
                  color="white"
                  min-width="auto"
                  class="px-0 text-decoration-underline fs-12"
                  @click="moreStore.setOrderModal(true)"
                  :style="myGroupDashboard.length < 2 ? 'visibility: hidden' : ''"
                  data-ko="모두보기"
                >
                  {{ $t("more.seeall") }}
                </VBtn>
              </div>

              <div class="jw-more-dashboard__body">
                <div class="dashboard-order--list">
                  <!-- <div v-for="(dashboard, index) in dashboardSetting.list" :key="`dashboard-order-${index}`" class="dashboard-order--item">
                    <VBtn icon color="transparent" :ripple="false" class="dashboard-order__show" @click.stop="() => { dashboard.show=!dashboard.show }">
                      <VIcon v-if="dashboard.show" size="20" color="white">jwIcons:svg/eye</VIcon>
                      <VIcon v-else size="20" color="#c4c4c4">jwIcons:svg/eye-closed</VIcon>
                    </VBtn>
                    <div v-if="dashboardSetting.orderEdit" class="order">
                      <v-btn size="28" icon variant="plain">
                        <div class="circle-ring"></div>
                      </v-btn>
                    </div>
                    <div v-else class="order">
                      <div class="order-num">{{ index + 1 }}</div>
                    </div>
                    <div class="title">{{ dashboard.grpName }}</div>
                  </div> -->
                  <div
                    v-for="(item, index) in myGroupDashboard"
                    :key="`dashboard-order-${index}`"
                    :class="`dashboard-order--item ${item.show ? 'item-show' : ''}`"
                  >
                    <VBtn
                      icon
                      color="transparent"
                      :ripple="false"
                      class="dashboard-order__show"
                      v-if="myGroupDashboard.length > 1"
                      @click.stop="toggleDashboardSetting(item, index)"
                    >
                      <VIcon v-if="item.show" size="20" color="white">jwIcons:svg/eye</VIcon>
                      <VIcon v-else size="20" color="#c4c4c4">jwIcons:svg/eye-closed</VIcon>
                    </VBtn>
                    <div class="order">
                      <div class="order-num dashboard-order-num" v-if="myGroupDashboard.length > 1 && item.show">
                        {{ dashboardOrderNum[index] }}
                      </div>
                    </div>
                    <div class="title">{{ item.info.grpName }}</div>
                  </div>
                </div>
              </div>
              <div class="dashboard-order--add">
                <VBtn
                  variant="flat"
                  height="25"
                  rounded
                  color="#2DA562"
                  class="fs-12 px-3"
                  @click.stop="router.push('/more/findMyGroup')"
                  data-ko="교회/단체 등록하기"
                  >{{ $t("more.regist.church") }}</VBtn
                >
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <v-container :fluid="!isPc" class="px-5 py-0 mt-n2">
        <v-list class="jw-more--links">
          <v-list-item v-for="(item, index) in moreLinks" :key="index" class="px-0" :class="{ new: item.isNew }">
            <VBtn variant="text" :href="item.url" :target="item.target" @click="onClickMoreLinks(item.target)">
              <VIcon size="36">{{ item.image }}</VIcon>
              <span class="text" v-html="item.text"></span>
            </VBtn>
          </v-list-item>
        </v-list>
      </v-container>

      <!-- 배너 시작 -->
      <div class="mb-5 mt-5">
        <MoreBanner />
      </div>
      <!--/ 배너 끝 -->

      <v-container class="px-5 py-0 mb-5">
        <v-list class="jw-link-border mt-5">
          <v-list-item class="px-2" v-for="(item, index) in links" :key="index" :href="item.url" :target="item.target">
            <span class="text">{{ item.text }}</span>
            <v-spacer></v-spacer>
            <VIcon size="24" color="#000000">jwIcons:svg/chevron-right-gray-thin</VIcon>
          </v-list-item>
        </v-list>

        <!-- <VBtn icon color="transparent" class="jw-more-question">
          <img src="~/assets/images/more-question.svg" />
        </VBtn> -->
      </v-container>
    </v-navigation-drawer>
    <VMain
      name="main"
      :style="{
        '--v-layout-top': isMobile ? '0' : '50px',
        '--v-layout-left': !isPc && isTablet && !isMobile ? '375px' : 0,
        '--v-layout-bottom': isMobile ? '55px' : 0,
        backgroundColor: isPc ? '#FFFCF8' : undefined,
        overflow: sideNavWrap.show && isMobile ? 'hidden' : undefined,
      }"
    >
      <v-container
        v-resize="onResize"
        :fluid="!isPc"
        class="jw-more--right"
        :style="{
          padding: 0,
          paddingLeft: isPc ? '375px' : 0,
          minHeight: isPc ? `calc(100vh - 210px)` : undefined,
          paddingTop: isPc ? '50px' : 0,
        }"
      >
        <div ref="jwMainContainer">
          <!-- {{ `isPc:${isPc}, isTablet:${isTablet}, isMobile:${isMobile}` }} -->
          <!-- <slot /> -->
          <ClientOnly>
            <NuxtPage />
          </ClientOnly>
        </div>
      </v-container>
    </VMain>
    <JwFooter v-if="showMainFooter" />
    <JwBottomNav
      v-if="!isPc"
      :style="{
        width: isMobile ? '100%' : '375px',
        left: isMobile ? 0 : undefined,
        paddingLeft: isMobile ? 0 : undefined,
        zIndex: 1007,
        borderRight: !isMobile ? '1px solid rgba(var(--v-border-color), var(--v-border-opacity))' : undefined,
      }"
    />
  </v-layout>

  <my-group-list-modal />
</template>
<script setup>
import { useDisplay } from "vuetify";
import { useMoreStore } from "~/store/more";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
// import MoreBanner from "~/components/more/MoreBanner.vue";

import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import JwFooter from "@/components/common/JwFooter.vue";
import MyGroupListModal from "@/components/more/myGrpListModal.vue";
//import * as ChannelService from "@channel.io/channel-web-sdk-loader";

const router = useRouter();
const route = useRoute();
const { smAndDown, xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const isMobile = computed(() => smAndDown.value);
const isTablet = computed(() => !isPc.value && !isMobile.value);
const { t } = useI18n();

const jwMainContainer = ref(null);

const moreStore = useMoreStore();

const defaultSwiperOption = reactive({
  slidesPerView: "auto",
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  spaceBetween: 10,
});

const sideNavWrap = reactive({
  width: "375px",
  height: "auto",
  show: true,
});

onBeforeMount(() => {
  getAlarmNotiCountAction();
  getMyGroupDashboardAction();
  getBannerAction();
});

onMounted(() => {
  onResize();
  /* await ChannelService.loadScript();
  ChannelService.boot({
    pluginKey: "a9413d0a-982e-42f8-97ed-86919a04609d", // fill your plugin key
    //customLauncherSelector: ".jw-more-question",
    hideChannelButtonOnBoot: false,
  }); */
});

onUpdated(() => {
  onResize();
});

watch(() => route.path, onResize);

async function onResize() {
  // await nextTick();

  sideNavWrap.width = !isMobile.value ? "375px" : "100%";
  sideNavWrap.height = jwMainContainer.value?.getBoundingClientRect().height + 50;
  // sideNavWrap.show = !isMobile.value || route.name == "more";

  if (!isMobile.value) {
    sideNavWrap.show = true;
  } else {
    if (route.name == "more") {
      sideNavWrap.show = true;
    } else {
      nextTick(() => {
        setTimeout(() => {
          if (isMobile.value && route.name != "more") sideNavWrap.show = false;
        }, 300);
      });
    }
  }
}

const showMainFooter = computed(() => {
  // if (["today-section-id", "shareDetail"].includes(route.name)) {
  //   return false;
  // } else {
  return isPc.value ? true : false;
  // }
});

/*
 * 새 알림/공지
 */
const { alarmNotiCount } = storeToRefs(moreStore);

async function getAlarmNotiCountAction() {
  await moreStore.getAlarmNotiCount();
}

watch(
  (v1 = route.meta.isAlarmPage, v2 = route.meta.isNoticePage) => {
    if (v1 || v2) getAlarmNotiCountAction();
  },
  { deep: true, immediate: true }
);

watch(
  (v1 = moreStore.orderModalClose) => {
    if (v1) refreshDashboardAction();
  },
  { deep: true, immediate: true }
);

/*
 * 내 교회/단체 대시보드
 */
const myGroupDashboard = reactive([]);

const dashboardOrderNum = ref([]);

async function getMyGroupDashboardAction() {
  await moreStore.getMyGroupDashboard();

  myGroupDashboard.length = 0;

  if (!isEmpty(moreStore.dashboardList)) {
    let newNum = 1;

    moreStore.dashboardList.map((itm, index) => {
      if (index < 3) {
        let isShow = itm.info.displayYn == "Y";

        dashboardOrderNum.value[index] = isShow ? newNum++ : 0;

        myGroupDashboard.push({
          ...itm,
          show: isShow,
        });
      } else {
        return false;
      }
    });
  }
}

function toggleDashboardSetting(dashboard, index) {
  const count = myGroupDashboard.length;
  let idx,
    newNum = 1,
    isShow = !dashboard.show;

  if (isShow) {
    dashboardOrderNum.value[index] = 1;

    for (idx = 0; idx < count; idx++) {
      if (dashboardOrderNum.value[idx]) {
        dashboardOrderNum.value[idx] = newNum++;
      }
    }

    dashboard.show = isShow;

    setTimeout(() => {
      const swiper = document.querySelector(".jw-swiper").swiper;
      swiper.slideTo(swiper.clickedIndex + 1);
    }, 300);
  } else if (dashboardOrderNum.value.filter((x) => x != 0).length != 1) {
    dashboardOrderNum.value[index] = 0;

    for (idx = 0; idx < count; idx++) {
      if (idx != index && dashboardOrderNum.value[idx]) {
        dashboardOrderNum.value[idx] = newNum++;
      }
    }

    dashboard.show = isShow;
  }

  // save api
  const payload = {
    grpCd: dashboard.info.grpCd,
    displayYn: isShow ? "Y" : "N",
  };

  moreStore.updateMyGroupDashboard(payload);
}

// 교회/단체 목록 팝업 닫은 후 refresh dashboard
function refreshDashboardAction() {
  getMyGroupDashboardAction();
  const swiper = document.querySelector(".jw-swiper").swiper;
  swiper.slideTo(0);
  moreStore.orderModalClose = false;
}

function onClickSermon(grpCd, videoUrl) {
  if (videoUrl != "") {
    router.push(`/more/sermon/${grpCd}`);
  } else {
    return false;
  }
}

function onClickWeekly(grpCd, uploadFile) {
  if (uploadFile != "") {
    router.push(`/more/weekly/${grpCd}`);
  } else {
    return false;
  }
}

function onClickAccount(grpCd) {
  if (grpCd != "") {
    router.push(`/more/offering/${grpCd}`);
  } else {
    return false;
  }
}

function onClickManage(grpCd) {
  if (grpCd != "") {
    router.push(`/more/manage/${grpCd}`);
  } else {
    return false;
  }
}

/*
 * 배너
 */
async function getBannerAction() {
  await moreStore.getBannerList();
}

/*
 * 링크
 */
const moreLinks = reactive([
  {
    image: "jwIcons:svg/more-edu",
    kor: "동행훈련",
    text: t("more.link.training"),
    url: "https://365.jwj.kr",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-sermon",
    kor: "유기성목사",
    text: t("more.link.pastoryou"),
    url: "https://www.youtube.com/@PastorYooKiSung",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-wjm",
    kor: "예수동행",
    text: t("more.link.withjesus"),
    url: "https://www.youtube.com/channel/UCDwDZlwDblLU7v7Jq_px89w",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-sponsor",
    kor: "후원",
    text: t("more.link.offering"),
    url: "",
    target: "sponsor",
  },
  {
    image: "jwIcons:svg/more-fellow",
    kor: "동역",
    text: t("more.link.cowork"),
    url: "",
    target: "fellow",
  },
  {
    image: "jwIcons:svg/more-right-now",
    kor: "RightNow<br />Media",
    text: t("more.link.media"),
    url: "https://www.rightnowmedia.org",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-gongdongche",
    kor: "공동체성경읽기",
    text: t("more.link.readbible"),
    url: "https://www.youtube.com/channel/UCISl2wEDnzYeg-k_kElfN4Q",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-make-book",
    kor: "동행일기<br />북만들기",
    text: "동행일기<br />북만들기",
    url: "",
    target: "_blank",
  },
]);

const links = reactive([
  {
    kor: "예수동행일기 안내",
    text: t("more.link.guide"),
    url: "https://wjm.kr/with-jesus-journal",
    target: "_blank",
  },
  {
    kor: "자주 묻는 질문",
    text: t("more.link.faq"),
    url: "https://withjesusministry.notion.site/WJM-7c859d3703ae4213b610f6565420b7f6",
    target: "_blank",
  },
  {
    kor: "문의하기",
    text: t("more.link.ask"),
    url: "https://pf.kakao.com/_YFxakj",
    target: "_blank",
  },
]);

function onClickMoreLinks(target) {
  if (target != "_blank") router.push(`/more/${target}`);
}
</script>
